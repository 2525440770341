<template>
  <div class="ArrowsControl">
    <table>
      <tr>
        <td><CircleButton icon="chevronLeft" :size="size || `36px`" @click="$emit('prev')" /></td>
        <td><Space :width="space || `10px`" /></td>
        <td><CircleButton icon="chevronRight" :size="size || `36px`" @click="$emit('next')" /></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["space", "size"],
};
</script>
